import ZuiApiService from '@zola-helpers/client/dist/es/http/api';

import * as ActionType from './types/NavActionTypes';
import ApiService from '../util/apiService';

const weddingCashFunds = {
  title: 'Cash Funds',
  path: '/shop/honeymoon-cash-funds',
};

const babyCashFunds = {
  title: 'Cash Funds',
  path: '/shop/cash-funds',
};

const requestCategories = () => ({
  type: ActionType.REQUEST_CATEGORY,
});

const receiveCategories = (response) => ({
  type: ActionType.RECEIVE_CATEGORY,
  payload: response,
});

export const getBabyCategories = () => (dispatch) => {
  dispatch(requestCategories());
  return ApiService.get('/web-registry-api/v1/nav/get/categories?businessUnit=BABY_REGISTRY')
    .then((json) => {
      json.categories.push(babyCashFunds);
      return json;
    })
    .then((json) => {
      dispatch(receiveCategories(json));
      return json;
    })
    .catch((e) => e); // error handled to be handled UnifiedNavV2
};

export const getCategories = () => (dispatch) => {
  dispatch(requestCategories());
  return ApiService.get('/web-registry-api/v1/nav/get/categories')
    .then((json) => {
      json.categories.push(weddingCashFunds);
      return json;
    })
    .then((json) => {
      dispatch(receiveCategories(json));
      return json;
    })
    .catch((e) => e); // error handled to be handled UnifiedNavV2
};

export const getWeddingShopCategories = () => (dispatch) => {
  dispatch(requestCategories());

  return ApiService.get('/web-registry-api/v1/nav/wedding-shop')
    .then((json) => {
      dispatch(receiveCategories(json));
      return json;
    })
    .catch((e) => e); // error handled to be handled UnifiedNavV2
};

export function getBabyNav() {
  return () => ZuiApiService.get('/website-nav/web-api/babyNav');
}

export function getNav() {
  return () => ZuiApiService.get('/website-nav/web-api/legacyNav');
}
